@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
	font-family: 'Roboto', sans-serif;
	background-color: #F9FAFB !important;
	line-height: 1.5;
}

/* body {
	
} */

 .dashboard {
	background-color: #F9FAFB;
	letter-spacing: -0.02em;
	/* background-image: url(./assets/images/executive-bg.jpeg); */
	background-image: url(./assets/images/executive-bg.jpg);
	background-size: cover;
	overflow: hidden;
	height: 100%;
	min-height: 100vh;
	background-attachment: fixed;
	background-position: center;
	font-family: 'Roboto', sans-serif !important;
}
#light .dashboard {
	background-color: #F9FAFB;
	letter-spacing: -0.02em;
	/* background-image: url(./assets/images/executive-bg.jpeg); */
	background-image: url(./assets/images/ExecutiveAustin.jpg);
	background-size: cover;
	height: 100%;
	min-height: 100vh;
	background-attachment: fixed;
	background-position: top;
	font-family: 'Roboto', sans-serif;
}
.logindashboard{
	background-image: url(./assets/images/GreeceLoginBg.png);
	background-color: #F9FAFB;
	letter-spacing: -0.02em;
	background-size: cover;
	height: 100%;
	min-height: 100vh;
	background-attachment: fixed;
	background-position: bottom;
	font-family: 'Roboto', sans-serif !important;
}
.complogo{
	height: 10vh;
    /* width: 20vh; */
    position: absolute;
    bottom: 4vh;
    right: 9vh;
}
.landingFrame{
	width: 60vh;
}

.dashBoardToptile
{
	background-color: #0B1D3F;
}
#light .dashBoardToptile
{
	background-color: #a19984;
}
.dashBoardToptile2 .desc_minheight{
	height: 30px;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* .dashBoardToptile2 .desc_minheight:hover{
	text-overflow: unset !important;
    overflow: visible !important;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    transform: translateY(5px);
    color: #fff;
    white-space: unset !important;
    background-color: #081630;
    cursor: pointer !important;
    height:100% !important;
} */
.dashBoardToptile1
{
	background-color: #081630;
}
#light .dashBoardToptile1
{
	background-color: #f2f4f9;
}

.dashBoardToptile2 >p
{
	
	color: #F9FAFB;
}
#light .dashBoardToptile2 >p
{
	color: #081630;
}

.dashboardmainfact >p
{
	letter-spacing: 0.2px;
	font-weight:500;
	color: #F9FAFB;
}
#light .dashboardmainfact >p
{
	color: #5e656d;
}
#light .dashboardmainfact > h6
{
	color: #5e656d;
}
#light .title {
	color: #555961;
	font-weight: 500;
	margin: 10;
}
#light .product-item-content{
	background-color:#fff;
}
.main-header-bg{
	background-color: transparent;
}
#light .main-header-bg{
	/* background-color: #f2f4f9; */
	background-color: #e6e3dc;
}


.EnrollmentTile
{
	background-color: #081630;
	color:  #f2f4f9;
}
#light .EnrollmentTile
{
	background-color: #f2f4f9;
	color:  #081630;
}
.StudentTile
{
	background-color: #081630;
	color:  #f2f4f9;
}
#light .StudentTile
{
	background-color: #f2f4f9;
	color:  #081630;
}
.logo {
    transition: all .5s ease-in-out;
}

.EnrollmentTitle
{
	color:  #f2f4f9;
	font-size: 14px;
}
#light .EnrollmentTitle
{
	
	color:  #081630;
}



.EnrollmentTitlee
{
	color:  #f2f4f9;
}
#light .EnrollmentTitlee
{
	
	color:  #f2f4f9;
}




.body{
	background-color: #F9FAFB;
	letter-spacing: -0.02em;
	/* background-image: url(./assets/images/executive-bg.jpeg); */
	background-image: url(./assets/images/executive-bg.jpg);
	/* background-image: url(./assets/images/executive-bg-update.png); */
	background-size: cover;
	overflow-x: hidden;
	height: 100%;
	min-height: 100vh;
	/* background-attachment: fixed; */
	background-position: top;
	font-family: 'Roboto', sans-serif;
	justify-content: center;

}

#light .body{
	background-color: #F9FAFB;
	letter-spacing: -0.02em;
	/* background-image: url(./assets/images/executive-bg.jpeg); */
	background-image: url(./assets/images/ExecutiveAustin.jpg);
	/* background-image: url(./assets/images/executive-bg-update.png); */
	background-size: cover;
	height: 100%;
	min-height: 100vh;
	background-attachment: fixed;
	background-position: top;
	font-family: 'Roboto', sans-serif;
}
/* .dashboard-background {
	background-image: url(./assets/images/executive-bg-update.png);
} */

.p-component
{
	font-family: 'Roboto', sans-serif !important;
	letter-spacing: 0.2px;
}
.p-inputtext , #viz_container text{
	font-family: 'Roboto', sans-serif;
}
/****************** scrollbar*************************/
::-webkit-scrollbar {
	width: 6px;
	cursor: pointer;
	background-color: #C4C4C4;
	border-radius: 5px;

}

::-webkit-scrollbar-track {
	background-color: rgba(229, 231, 235, var(--bg-opacity));
	cursor: pointer;
	background: #f2f2f2;

}

::-webkit-scrollbar-thumb {
	cursor: pointer;
	background-color: #B3DDC7;
	/*outline: 1px solid slategrey;*/
	border-radius: 5px;
}

.dark ::-webkit-scrollbar-thumb {
	cursor: pointer;
	background-color: #333;
	/*outline: 1px solid slategrey;*/
	border-radius: 5px;
}

.dark ::-webkit-scrollbar {
	width: 6px;
	cursor: pointer;
	background-color: #667085;
	border-radius: 5px;

}

.dark ::-webkit-scrollbar-track {
	background-color: #667085;
	cursor: pointer;


}

/****************** scrollbar end*************************/
.title {
	font-size: 25px;
	font-weight: 400;
    letter-spacing: -.2px;
    color: #fff;
	
}

.subtitle {
	font-size: 12px;
	color: #141414;
	font-weight: 500;
}

.profile svg {
	color: transparent !important;
	fill: transparent !important;
}

.profile-block {
	padding: 16px 18px;
	border-radius: 10px 0px 0px 10px;
}

.baricon i {
	font-size: 20px;
	color: #fff;
}
#light .baricon i{
	color: #555961;
}

.DarkmodeBarIcon i{
	font-size: 24px;
	color: #fff;
}
.LightmodeBarIcon i{
	font-size: 24px;
	color: #555961;
}

.MuiAvatar-root {
	width: 35px !important;
	height: 35px !important;
}

/**********Sidebar*****************/
.p-sidebar-right .p-sidebar
{
	width: 25rem;
    height: 100%;
    margin-top: 30px;
	background-color: rgb(8, 22, 48);
}
.p-sidebar .p-sidebar-header
{
	padding: 0;
    display: none;
}
 .p-sidebar .p-sidebar-content
{
	padding-right: 0 !important;
	padding-left: 0 !important;
}
.p-sidebar-content .p-tabview .p-tabview-panels
{

    background-color: rgb(8, 22, 48);
}
.DarkmodePopup .p-sidebar-content  .p-tabview .p-tabview-nav
{
	background-color: #0a1630 !important;
	display: flex;
    align-items: center;
	border: 1px solid #0a1630 !important;
}
.LightmodePopup .p-sidebar-content  .p-tabview .p-tabview-nav
{
	background-color: #a19984 !important;
	display: flex;
    align-items: center;
	border: 1px solid #a19984 !important;
}

.p-tabview .p-tabview-nav li
{
	margin-right: 15px !important;
}
.p-sidebar-content .p-tabview .p-tabview-nav .p-tabview-ink-bar
{
	top: 0;
	background-color:#a19984 !important;
	border-radius: 5px 5px 0px 0px !important;
	height: 5px !important;
}
.p-sidebar-content .p-tabview-selected 
{
	background-color: rgb(13, 35, 76);
}

.LightmodePopup .p-sidebar-content .p-tabview-selected 
{
	
	background-color: #e6e3dc
}
.p-sidebar-content .p-tabview .p-tabview-panels
{
	background: #0a1630 !important;
	padding: 0 !important;
}
.menu ul li 
{
	padding: 8px 10px;
	font-size: 16px;
	color: #fff;
	margin-bottom: 5px;
}
.menu ul li:hover 
{
	background-color: #a19984;
    color: #fff;
}
.menu ul .active
{
	background-color: #a19984;
	/* text-shadow: 0 -1px 0 rgb(0 0 0 / 25%); */
	color: #fff;
}



/*Light theme sidebar*/

/**********Sidebar*****************/
/**********Dashboard *****************/
.main-facts
{
	 background: linear-gradient(90deg, rgb(10, 66, 99) 0%, rgba(66, 48, 70, 0.8) 65%, rgb(26, 57, 77) 100%);
    box-shadow: unset;
    width: 100%;
    height: 115px;
    display: block;
    color: rgb(255, 255, 255);
}
.main-facts .p-carousel-items-container
{
	/* gap:7px !important;
	position: relative; */
	/* margin-right: 7px !important; */
}
/* .main-facts .p-carousel-item.p-carousel-item-active.p-carousel-item-start{
	margin-right: 7px;
} */
.main-facts .p-carousel .p-carousel-indicators 
{
	display: none;
	
}

#light .p-carousel-items-container
{
	/* background: #f2f4f9; */
}


.performingschools{
	background: #101b29 !important;
}

#light .performingschools{
	background: #a19984 !important;
}

.main-facts .p-carousel .p-carousel-content .p-carousel-prev
{
	width: 20px;
	height: 20px;
	position:absolute;
	left: 0;
	/* background-color: #6C757D !important; */
}
.main-facts .p-carousel .p-carousel-content .p-carousel-next
{
	width: 20px;
	height: 20px;
	position:absolute;
	right: 0;
	/* background-color: #6C757D !important; */
}
.main-facts .info
{
	width: 144px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(235, 235, 235);
}
.main-facts .hovertitle:hover
{
	
	width: 144px;
    text-overflow: unset !important;
    overflow: visible !important;
    transition: all 0.8s cubic-bezier(.25,.8,.25,1);
    transform: translateY(5px);
    color: #fff;
    white-space: unset !important;
    font-size: 12px;
    background-color: #0c1b28;
    cursor: pointer !important;
    line-height: 14px;
	height: 100%;
}
.smallarrowbtn
{
	
    border: 1px solid #1C6130;
    border-radius: 20px;
    background-color: #1C6130;
    display: flex;
    justify-content: center;
    color: rgb(255, 255, 255);
	font-size: 10px;
	padding: 0px 3px;
	gap: 3px;
    justify-content: center;
    align-items: center;
}
.smallarrowbtndown
{
	width: max-content;
    border: 1px solid #6A1D25;
    border-radius: 20px;
    background-color: #6A1D25;
    display: flex;
    justify-content: center;
    color: rgb(255, 255, 255);
	font-size: 10px;
	padding: 0px 3px;
	gap: 3px;
    justify-content: center;
    align-items: center;
}
.StudentEnrollmentbyYear .echarts-for-react 
{
	height: 120px !important;
}
.RevenueExpense .echarts-for-react 
{
	height: 110px !important;
	top:-25px !important;
	right:-12px !important;
}
.StudentCenters .echarts-for-react 
{
	/* height: auto !important; */
	height: 540px !important;
	top: -140px !important;
}
.FinInfo1 .echarts-for-react 
{
	height: 220px !important;
	top: -10px;

}
.FinInfo2 .echarts-for-react 
{
	height: 220px !important;
	top: -10px;
}

.dashboard-silder .p-carousel-items-container
{
	gap:0px !important;
	position: relative;
}
.dashboard-silder .p-carousel .p-carousel-indicators 
{
	display: none;
	
}
.dashboard-silder .p-carousel .p-carousel-content .p-carousel-prev
{
	width: 20px;
	height: 20px;
	position:absolute;
	left: 0;
}
.dashboard-silder .p-carousel .p-carousel-content .p-carousel-next
{
	width: 20px;
	height: 20px;
	position:absolute;
	right: 0;
}
.dashboard-silder .p-carousel-item .product-item
{
	margin-right: 10px;
	height: 100%;
}
.dashboard-silder
{
	margin-top: 100px;
}
.StudentCharacteristics .echarts-for-react 
{
	height: 150px !important;
}
.StudetGroup .echarts-for-react 
{
	height: 300px !important;
	width: 100%;
}
/**********Dashboard end*****************/

/*****************shahrukh css********************/
.cohortbox-carousel .product-item {
	margin: 0 5px;
}

.cohortbox {
	background-color: #16203f;
	border-radius: 7px;
	padding: 10px 15px;
}

.cohortheading p {
	color: white;
	font-weight: 100;
	font-size: 16px;
}

.cohortbox .cohrtconten span {
	color: white;
	font-size: 11px;
	font-weight: 100;
}

.cohortbox .cohrtconten p {
	color: white;
	font-size: 22px;
}
.studentenrolled{
	background-color: #2f3854;
}

.studentenrolled-box {
    display: flex;
    justify-content: start;
    border-right: 2px solid #dddddd;
}

.studentenrolled-box p {
    color: #c5c5c5;
    font-size: 17px;
}
.numberofyear {
    display: flex;
    align-items: end;
}
.numberofyear h4 {
    font-size: 24px;
    color: #ffffff;
}
.numberofyear span {
    font-size: 12px;
    color: #dbdbdb;
}

.trending {
    display: flex;
    background: #28ae61;
    align-items: center;
    color: #fff;
    justify-content: space-between;
    border-radius: 20px;
    width: 52px;
    padding: 0px 4px;
    font-size: 13px;
}
.trending img {
    width: 15px;
    object-fit: contain;
}
@media (max-width: 640px) { 
	.numberofyear .counting {
		margin-right: 24%;
	}
	.studentenrolled-box {
		border-bottom: 2px solid #dddddd;
		border-right: 0;
	}
 }

@media (min-width: 768px) {
	.numberofyear .counting {
		margin-right: 37%;
	}
 }

 @media (min-width: 1024px) { 
	.numberofyear .counting {
		margin-right: 42%;
	}
  }

/*****************shahrukh css********************/

.wrapper-card .p-card {
	background-color: rgb(39, 48, 74, 0.8) !important;
	border-radius: 2px !important;
}

#light .wrapper-card .p-card {
	background-color: #f2f4f9 !important;
	border-radius: 2px !important;
}

.wrapper-card .title-text {
	/* font-size: 20px;
	font-weight: 700; */
	/* padding: 0 20px; */
	color: #fff;
}

#light .wrapper-card .title-text {
	/* font-size: 20px;
	font-weight: 700; */
	/* padding: 0 20px; */
	color: #081630;
}

.wrapper-card .p-dropdown {
	display: flex;
	align-items: center;
	background-color: #081630;
	color: #fff;
	border-radius: 40px;
	height: 30px;
	border: none !important;
	/* width: 100% !important; */
}

#light .wrapper-card .p-dropdown {
	display: flex;
	align-items: center;
	background-color: #fff;
	color: #081630;
	border-radius: 40px;
	height: 30px;
	border: none !important;
	
}


#light .p-inputtext
{
	color: black;
}

#light .pi-chevron-down
{
	/* color: black; */
	color: #fff;
}

.wrapper-card .p-dropdown .p-dropdown-label .p-placeholder {
	color: #fff !important;
}

#light  .wrapper-card .p-dropdown .p-dropdown-label .p-placeholder {
	color: #081630 !important;
}
.wrapper-card .labeltext {
	color: #fff;
	font-size: 12px;
	font-weight: 400;
	margin-top: -4%;
	margin-left: 4%;
}
#light .wrapper-card .labeltext {
	color: #081630 ;
	font-size: 12px;
	font-weight: 600;
	margin-top: -4%;
	margin-left: 4%;
}

.wrapper-card .p-card .p-card-content {
	padding: 0px !important;
}

.wrapper-card2 {
	background-color: rgb(27, 20, 34);
	color: rgb(255, 255, 255);
}

#light .wrapper-card2 {
	background-color: #f2f4f9;
	color: rgb(255, 255, 255);
}
.slide-header {
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	margin: 0px 0px 0px 0px;
	min-height: 50px;
}


.donut-chart-wrap { height: 400px; }
.donut-chart-wrap #part1 { background-color: rgba(82,114,135,.65); display: flex; align-items: end; justify-content: end; }
.donut-chart-wrap #part2 { background-color: rgba(81,115,108,.65); display: flex; align-items: end; justify-content: start; }
.donut-chart-wrap #part3 { background-color: rgba(78,86,116,.5); display: flex; align-items: start; justify-content: end; }
.donut-chart-wrap #part4 { background-color: rgba(123,84,108,.5); display: flex; align-items: start; justify-content: start; }

.donut-chart-wrap .portion-block { margin: 5px;}
.quarter-circle-top-left, .quarter-circle-top-right, .quarter-circle-bottom-left, .quarter-circle-bottom-right{ width: 180px; height: 92%; position: relative; }

.quarter-circle-top-left { border-radius: 200px 5px 0 5px; box-shadow: inset 0 -3em 3em #1a19191a, 0 0 0 2px rgb(2 111 187), 0.3em 0.3em 2em rgb(0 0 0); background: #2c81bc;}
.quarter-circle-top-left .label-container { height: 100%; transform: rotate(-45deg); }

.donut-chart-wrap .label{ position: relative; color: #fff; font-size: 12px; text-align: center;}
.donut-chart-wrap .label { top: 41px; }

.quarter-circle-top-right { border-radius: 5px 200px 5px 0; box-shadow: inset 0 -3em 3em #1a19191a, 0 0 0 2px rgb(19 151 127), 0.2em 0.2em 1em rgb(0 0 0); background: #129a82; }
.quarter-circle-top-right .label-container { height: 100%; transform: rotate(45deg); }

.quarter-circle-bottom-left { border-radius: 5px 0 5px 200px; box-shadow: inset 0 3em 3em #1a19191a, 0 0 0 2px #59318d, 0.3em 0.3em 2em #000; background: #542b88; }
.quarter-circle-bottom-left .label-container { height: 100%; transform: rotate(-135deg); }
.quarter-circle-bottom-left .label-container .label { transform: rotate(180deg); }

.quarter-circle-bottom-right { border-radius: 0 5px 200px 5px; box-shadow: inset 0 -3em 3em #1a19191a, 0 0 0 2px rgb(137 39 103), 0.1em 0.1em 1em rgb(0 0 0); background: #892c69; }
.quarter-circle-bottom-right .label-container { height: 100%; transform: rotate(135deg); }
.quarter-circle-bottom-right .label-container .label { transform: rotate(180deg); }

.center-component { width: 320px; height: 320px; background-color: white; box-shadow: 0 0 30px #413e3e; border-radius: 50%; display: flex; align-items: center; justify-content: center; }










.wrap-component{
	background-color: rgb(39, 48, 74, 0.4) !important;
    /* backdrop-filter: blur(3px); */
    border-radius: 5px;
	box-shadow: 0 0 0 #fff!important;
}

.wrap-component .p-datatable .p-datatable-tbody > tr{
	/* background-color: rgba(17,32,64,0.4) !important; */
	color: #fff !important;
    transition: box-shadow 0.2s;
}
.wrap-component tr:nth-child(even){
	background-color: #16203f !important;
}
#light .wrap-component tr:nth-child(even){
	background-color: #a19984 !important;
}


.wrap-component tr:nth-child(odd){
	background-color: rgba(17,32,64,0.4) !important;
}

.wrap-component .p-datatable .p-datatable-tbody > tr > td{
	text-align: left;
    border-bottom: 1px solid rgb(255 255 255 / 18%) !important;
	font-size: 12px !important;
    border-width: 0 0 1px 0;
    padding: 0.5rem 0.9rem !important;
	min-height: 30px;
}
.wrap-component .p-datatable .p-datatable-thead > tr > th{
	background-color: #081631 !important;
	color: #fff !important;
	font-weight: 600 !important;
    font-size: 13px !important;
	padding: 0.5rem 0.9rem !important;
border-bottom: none !important;
}
.legendbox{
	box-shadow: rgb(0 0 0 / 20%) 0px 0px 0px 0px;
    background-color: rgb(243, 243, 244);
	padding: 5px 10px;
	border-radius: 5px;
}
.legend-header{
	min-height: 25px;
	line-height: 10px;
	font-weight: 500;
}
.legendbox{
	border-left: 10px solid rgb(43, 61, 130);
}
.wrap-component .p-carousel-indicators,
.carousel-holder1 .p-carousel-indicators{
	display: none !important;
}

.wrap-component .p-dropdown{
	height: 35px !important;
    background: #2c4089 !important;
    border-radius: 100px;
    max-width: 160px;
    width: 160px !important;
    display: flex !important;
	border: none !important;
}
.wrap-component .p-dropdown .p-dropdown-label.p-placeholder{
	color: #fff !important;
padding: 0px 10px !important;
font-size: 13px !important;
}

.carousel-holder1{
	background-color: transparent;
	margin-top: 5px;
}
.cohortbox1{
	width: 100%;
    background-color: rgb(39, 40, 61);
    color: white;
    margin-bottom: 0px;
	border-radius: 7px;
	padding: 10px 15px;
}

.cohortbox2{
	width: 100%;
    background-color: #fff;
    color: #000;
    margin-bottom:0px;
	border-radius: 7px;
	padding: 10px 15px;
}
.cohortbox1:hover{
	width: 100%;
    background-color: #fff;
    color: #000;
	border-radius: 7px;
}
.cohortbox-carousel1 .text-white:hover{
	color: #000;
}

.cohortbox-carousel1 .heading-text{
	height: auto !important;
    color: #fff;
    font-size: 14px;
    margin: 3px 15px;
    font-weight: 100;
    margin-top: 5px
}
.cohortbox-carousel1 .product-item{
	margin: 0 5px;
}

.carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(1),
.carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(2){
	/* background-color: rgba(73, 115, 110, 0.3); */
	background-color: rgba(73, 87, 115, 0.3);
}

#light .carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(1),
#light .carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(2){
	/* background-color: rgba(73, 115, 110, 0.3); */
	background-color: #f2f4f9;
}


.carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(3),
.carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(4),
.carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(5){
	background-color: rgba(73, 87, 115, 0.3);
}

#light .carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(3),
#light .carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(4),
#light .carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(5){
	background-color: #f2f4f9;
}

.carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(6),
.carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(7),
.carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(8),
.carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(9),
.carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(10),
.carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(11),
.carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(12),
.carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(13)
{
	/* background-color: rgba(135, 46, 106, 0.3); */
	background-color: rgba(73, 87, 115, 0.3);
}

#light .carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(6),
#light .carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(7),
#light .carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(8),
#light .carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(9),
#light .carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(10),
#light .carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(11),
#light .carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(12),
#light .carousel-holder1 .p-carousel .p-carousel-item:nth-of-type(13)
{
	/* background-color: rgba(135, 46, 106, 0.3); */
	background-color: #f2f4f9;
}

.carousel-holder1 .p-carousel .p-carousel-content .p-carousel-prev, 
.carousel-holder1 .p-carousel .p-carousel-content .p-carousel-next{
	width: 30px !important;
    height: 30px !important;
    color: #ffffff;
	border-radius: 10px !important;
	margin: 3px !important;
    background-color: rgba(255,255,255,0.20) !important;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin:0;
}
.carousel-holder1 .p-carousel-content .pi {
	font-size: 1rem !important;
}
.carousel-holder1 .p-carousel .p-carousel-content .p-carousel-prev:focus,
.carousel-holder1 .p-carousel .p-carousel-content .p-carousel-next:focus{
	box-shadow: none !important;
}

.vertical{
	height: 100%;
	/* background: linear-gradient(0deg, #1D2C4E 86%, #101B25 60%) !important; */
	 border-radius: 17px;
    opacity: 0.8;
	width: 100%;
	padding: 15px;
}
.vertical .progress-bar {
	/* float: left; */
	height: 100%;
	/* width: 40px; */
	/* margin-right: 10px; */
	
   
  }
  .vertical .progress-track {
	position: relative;
	/* width: 40px; */
	height: 100%;
	/* background: #1D2C4E ; */
	/* border: 1px solid #ebebeb; */
  }
  .vertical .progress-fill {
	position: relative;
	/* height: 50%; */
	width: 40px;
	color: #fff;
	text-align: center;
  }

  .savage-text{
	font-size: 10px;
    color: rgb(255, 255, 255);
    margin-right: 10px;
    opacity: 0.5;
    min-width: 77px;
  }
  .echart .pi-arrow-down:before{
	font-weight: 800 !important;
	margin-bottom: 20px;
  }

 
.graduationtable .table-auto p {
    color: #fff;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.2px;
}
.graduationtable .table-auto tr{
	background-color: #0b18239e;
	margin-left: 50px;
	height: 40px;
}
.graduationtable .table-auto {
    width: 100%;
}
.graduationtable .table-auto thead tr th {
    color: #fff;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.2px;
    text-align: left;
}

.graduationtable .table-auto tbody tr {
    border-top: none;
}

.strength .firstrowtext{
	font-size: 14px;
    margin-right: 5px;
    /* width: 100%; */
    line-height: 1;
    /* opacity: 0.5; */
}

#light .strength .firstrowtext{
	font-size: 14px;
    margin-right: 5px;
    /* width: 100%; */
    line-height: 1;
    opacity: 1;
	color: black;
}
.strength .p-carousel .p-carousel-indicators{
display: none;
}
.strength .p-carousel .p-carousel-item{
	background: transparent !important;
}

.strength .p-carousel .p-carousel-content .p-carousel-prev,
.strength .p-carousel .p-carousel-content .p-carousel-next{
	display: none;

}

.progresslabeltext {
    position: absolute;
    right: 20%;
    font-size: 13px;
	color: #fff;
}
.progresslbl {
    position: absolute;
    right: 20%;
    font-size: 3px;
	color: transparent;
	height: 5px !important;
}
.progresslabeltext1{
	position: absolute;
    right: 5%;
    font-size: 13px;
	color: #fff;
}

.basic-container-child{
	background-color: #0d1b28;
	padding: 10px;
	border-radius: 5px;
}

#light .basic-container-child{
	background-color: #a19984;
	padding: 10px;
	min-height: 217px;
	border-radius: 5px;
}

.basic-container-child1{
	background-color: #0d1b28;
	
}

#light .basic-container-child1{
	background-color: #a19984;
	
}
.basic-container-child .relative div{
	height: 8px !important;
}
.movement-container{
	
    background: linear-gradient( 90.43deg, rgba(53, 74, 86, 0.87) 1%, rgba(65, 63, 86, 0.87) 29.08%, rgba(62, 81, 81, 0.8613) 65.48%, rgba(35, 71, 92, 0.87) 95.12% );
    left: 0;
    height: 37%;
    width: 100%;
	padding: 9% 2% 1%;
    margin-top: -8%;
}

#light .movement-container{
	
    background: #f2f4f9;
    left: 0;
    height: 37%;
    width: 100%;
	padding: 9% 2% 1%;
    margin-top: -8%;
}

.movement-container .pi-arrow-left:before{
	font-size: 10px !important;
	font-weight: 900 !important;
}

.gradselect .p-dropdown{
	margin-top: -20px;
	height: 30px !important;
    background: rgba(46, 38, 90, 255) !important;
    border-radius: 100px;
    max-width: 164px;
	border: none !important;
}
.gradselect .p-dropdown .p-dropdown-label.p-placeholder{
	color: #fff !important;
	display: flex;
    align-items: center;
	font-size: 12px !important;
}

.incidents-measures .p-tabview .p-tabview-nav li{
margin-right: 0 !important;
}
.paginationprevious.disabled{
	opacity: 0.5;
  /* pointer-events: none; */
	cursor: not-allowed;
	font-size: 12px;
	color: #ffffff;
}
.paginationnext.disabled{
	opacity: 0.5;
  /* pointer-events: none; */
	cursor: not-allowed;
	font-size: 12px;
	color: #ffffff;
}

.paginationprevious{
	font-size: 12px;
	color: #ffffff;
}
.paginationnext{
	font-size: 12px;
	color: #ffffff;
}



.paginationprevious1.disabled{
	position: relative;
	bottom:5px;
	opacity: 0.5;
  /* pointer-events: none; */
	cursor: not-allowed;
	font-size: 12px;
	color: #ffffff;
}
.paginationnext1.disabled{
	position: relative;
	bottom:5px;
	opacity: 0.5;
  /* pointer-events: none; */
	cursor: not-allowed;
	font-size: 12px;
	color: #ffffff;
}

.paginationprevious1{
	position: relative;
	bottom:5px;
	font-size: 12px;
	color: #ffffff;
}
.paginationnext1{
	position: relative;
	bottom:5px;
	font-size: 12px;
	color: #ffffff;
}

.head{
    background: #181725;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    padding: 7px 0;
}

.p-carousel .p-carousel-indicators 
{
	display: none;
	
}
.school_per_sldr .p-carousel-container {
	width: 80% !important;
	margin: 0 auto !important;
	margin-bottom: 15px !important;
}

.school_per_sldr  .p-carousel .p-carousel-content .p-carousel-prev, .school_per_sldr .p-carousel .p-carousel-content .p-carousel-next {
	/* width: 2.5rem !important;
	height: 5rem !important;
	color: #ffffff;
	border: 0 none; */
	background-color: black !important;
	color: #ffffff;
	/* border-radius: 1px !important;
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	margin: 0; */
}


.previousstyle{
	font-size: 10px;
	background: black;
	width: 118px;
	border-radius: 4px;
	text-align: center;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 11px;
}
#light .cohortbox2 {
	background-color : #fff;
}
#light .cohortbox1 {
	background-color : #a19984;
}
#light .cohortbox1:hover {
	background-color : #fff;
}

#light .wrap-component {
	background-color : #f2f4f9 !important;
}

#light .legendbox {
	background-color : #a19984
}

#light .tileTitleColor {
	color : #fff;
}

.performance-summary {
		background-color: #081631;
}
#light .performance-summary {
	background-color: #a19984;
}
#light .wrap-component tr.summary{
	background-color : #fff !important;
}

#light .graduationtable .table-auto tr.summary p {
	color: #000 !important;
}
.table-summary-footer {
	background-color: #1d2634 !important;
}

#light .table-summary-footer {
	background-color: #a19984 !important;
}
.swiper-slide{
	height: auto !important;
}
.EnrollmentTile {
	height: 85%;
	border-radius: 7px;
}
.swiper-slide img {
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
	border-radius: 7px;
}
.swiper-button-next:after,.swiper-button-prev:after {
    font-size: 15px!important;
    background: #a19984;
    color: #fff;
    width: 20px!important;
    height: 20px;
    border-radius: 30px;
    line-height: 21px!important;
    text-align: center
}

.swiper-button-prev {
    left: -2px!important
}

.swiper-button-next {
    right: -2px!important
}

.swiper-scrollbar.swiper-scrollbar-horizontal {
    display: none !important;
}
.swiper-pagination {
    display: none;
}
/* #light .collegejoindata .table-auto tbody tr {
	background-color : #fff !important;
} */


.footer {
	font-style: normal;
    font-weight: 300 !important;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: .2px;
    color: #fff;
	/* background-color: #232528; */
}

#light .footer {
	background-color: #a19984;
}

#light .carousel-holder1 .p-component .p-carousel-content .p-carousel-container button {
	background-color: #a19984 !important;
    opacity: 0.4;
    margin: 3px;
    width: 30px !important;
    height: 30px !important;
    border-radius: 10px !important;
}
.EnrollmentTitle.EnrollmentTitle_number{
	font-size: 14px;
	font-weight: 500;
	text-align: right;
    padding-right: 10px;
}
 .movement-container .table_text {
	color: #fff;
}
#light .movement-container .table_text {
	color: #081630;
}
#light .wrap-component .p-dropdown{
	background: #ffffff !important;
	border:1px solid #2c4089 !important;
}
#light .wrap-component .enrollselect .p-dropdown .p-dropdown-trigger  .pi-chevron-down{
	color: #081630;
}
#light .pagination a , #light .pagination span{
	color: #081630;
}
.environment_details_col{
	padding: 10px;
    background-color: rgb(39, 48, 74, 0.4);
    border-radius: 5px;
}
#light .environment_details_col{
    background: #fcfcfc;
}

.LightmodePopup .sidebar-panel{
	background: #fcfcfc;
}
.LightmodePopup .sidebar-panel h2, .LightmodePopup .sidebar-panel p ,
.LightmodePopup .sidebar-panel div{
	color: #081630;
}
.LightmodePopup .p-sidebar-content .p-tabview .p-tabview-panels{
    background: #fcfcfc !important;
    padding: 0 !important;
}
.LightmodePopup .menu ul li{
	color:#081630;
}
.LightmodePopup .menu ul li:hover{
	background-color: #a19984;
	color:#fff;
}

.LightmodePopup .menu ul .active{
 color: #fff;

}
.LightmodePopup.p-sidebar{
	background: #fcfcfc;
}
.DarkmodePopup  .p-tabview .p-tabview-panels{
	color:#fff;
}

#light .dimension_dropdown .p-dropdown-trigger .pi-chevron-down {
	color: black;
	font-size: 12px;
}

.pi-chevron-down {
	color: white;
}

.drilldown_card .p-card .p-card-body{
	min-height: 494px !important;
}
.timetreanding-carousel + .pb-10 {
	padding-bottom: 0px !important;
}

.search_dropdown .p-icon-field .ged-search.p-input-icon {
    height: 40px;
    top: 0;
    margin: 0;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 8px 8px 0;
    border: 1px solid #374151;
    background: #111928;
    right: 0;
}

.search_dropdown .p-inputtext {
    font-size: 14px;
    color: #fff;
}

.search_dropdown .p-icon-field .p-inputtext {
    border-radius: 0 8px 8px 0;
    border: 1px solid #374151;
    background: #1f2a37;
}

.search_dropdown .p-dropdown{ 
	border-radius: 8px 0 0 8px;
	border: 1px solid #374151;
	background: #263040
}
.custmPanel.p-dropdown-panel {
    background: #263040
}
.p-hidden-accessible {
    border: 0;
    padding: 0;
    margin: -1px;
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    white-space: nowrap;
}
/* .ged-search:before {
    content: "\e905";
	color: white;
} */

/* .p-icon-field {
    position: relative;
} */
/* .p-dropdown {
    cursor: pointer;
    position: relative;
    user-select: none;
} */
/* .p-component, .p-component * {
    box-sizing: border-box;
} */
.goalsandStrategy{
	background-image: url(./assets/images/landing_wrap.png) ;
}
.custmPanel.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    background-color: #111928;
    color: #fff;
}
.p-dropdown-panel > .p-dropdown-items-wrapper .p-dropdown-item{
	background-color: #1F2A37;
}

/*  */
.goalsandStrategy .p-inputtext {
    border-radius: 0 8px 8px 0;
    border: 1px solid  #374151;
    background: #1f2a37;
	font-size: 14px;
	color: #fff;
}

.goalsandStrategy input:focus {
	outline: none;
}

/* goalsandstrategy sidebar */
/* Courosel */
.customsidebar .p-carousel-prev-icon,
.customsidebar .p-carousel-next-icon {
  display: none;
}
/* Courosel */

/* SideBar */
.p-sidebar-right .customsidebar.p-sidebar{
	margin-top: 0;
}

.customsidebar.p-sidebar .p-sidebar-header {
  display: block;
  padding: 0;
}

.customsidebar.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover, .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: transparent;
    border-color: transparent;
    background: transparent;
    background-image: url(./assets/images/close-btn.svg);
    position: absolute;
    left: -75px;
    top: 50%;
    bottom: 50%;
    width: 56px;
    height: 56px;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 5.427vw;
}
.customsidebar.p-sidebar .p-sidebar-header .p-sidebar-close.p-sidebar-icon {
    position: absolute;
    left: -75px;
    top: 50%;
    bottom: 50%;
    width: fit-content;
    height: fit-content;
    font-family: "austin" !important;
    content: "\e80c" !important;
    background-image: url(./assets/images/close-btn.svg);
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 5.427vw;
    color: transparent;
    content: "";
    width: 56px;
    height: 56px;
    display: block;
}
.customsidebar.p-sidebar .p-sidebar-header .p-sidebar-close, .customsidebar.p-sidebar .p-sidebar-header .p-sidebar-icon {
    border-radius: 0px;
}
.customsidebar.p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 0;
    /* transition: background-color 0.2s, color 0.2s, box-shadow 0.2s; */
}
/*  */

.customsidebar .custdropdown.p-dropdown {
  background: transparent;
  border: 0;
}

.custdropdown.p-dropdown-panel{
	background-color: #1f2a37;
}
.custdropdown.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: .5rem 1rem;
  border: 0;
  transition: box-shadow .2s;
  border-radius: 0;
  background-color: #1f2a37;
  color: #fff;
}
.custdropdown .p-dropdown-item {
  cursor: pointer;
  font-weight: normal;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}
.custdropdown.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #fff;
  background: #1f2a37;
}
.custdropdown.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background: #1f2a37;
}
.custdropdown.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  background-color: #111928;
  color: #fff;
}

.customsidebar .custdropdown2.p-dropdown {
  background: #263040;
  border: 1px solid #374151;
  /* padding: .5rem 1rem; */
}

.custdropdown2 .p-inputtext {
    margin: 0 !important;
    color: white !important;
}
.custdropdown .p-inputtext {
    margin: 0 !important;
    color: white !important;
}

.benchmarkScroll .p-scrollpanel-content {
	overflow-x: hidden;
}