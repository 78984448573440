#viz_container text {
    /* fill: var(--is-text) !important; */
    font-size: 14px !important;
    transform: translateY(-16px);
}
.studenteoc-civi .p-inputtext{
    font-size: 14px;
}
canvas{
    font-family: 'Roboto', sans-serif;
}
.echarts-for-react div{
    font-family: 'Roboto', sans-serif;
}
#viz_container {
    /* transform: translateY(-20%); */
    height: 100% !important;
    margin-top: 70px !important;
}
.vizuly.vz-weighted_tree-viz{
    /* background-color:var(--is-theme-bg); */
    /* min-height: 50vh; */
    width: 100% !important;
}
.vz-background{
    /* background-color:var(--is-theme-bg) !important; */
}
/* div .vz-weighted_tree-viz{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
} */

.FinInfo1 {
    height: 85px !important;
}
.FinInfo2 {
    height: 95px !important;
}
