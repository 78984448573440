
.loginImg{
    height: 50rem;
    width: 60rem;
} 
.loginLeft{
    background-color:rgba(12, 12, 151, 0.397);
    height: 100%; 
}
.LoginDet{ 
    padding-top: 2vh;
}
.Loginlogo {
    /* height: 120px; */
    width: 60%;
    margin-right:auto ;
    /* padding-top: 10px; */
}
.loginmain_div{
	width: 100%;
	text-align: left;
    padding-left: 6vw;
    padding-top: 2vw;
    overflow: hidden;
}
.login_title{
    font-size: 50px;
    color: #fff;
    line-height: 1.2;
}
.login_titlebottom{
    font-size: 20px;
    color: #fff;
}
.login_action_div{
    background: #fff;
    color: #000;
    padding: 2vh;
    text-align: left;
    border-radius: 10px;
    width: 60vh;
    margin: auto;
    position: relative;
    top: 40vh;
}
.login_action_div p{
    color: #000;
    text-align: left;
    margin: 0;
    padding-left: 25px;
}
.login-button-azure,
.login-button-azure:hover,
.login-button-azure:focus{
    margin: auto;
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    background-color: #28364F;
    color: white;
    font-weight: 600;
    width: 50vh;
    font-size: 14px;
    border: gray 1px solid;
    border-radius: 5px;
    padding: 7px;
}

.loginName{
    text-align: center;
    font-size: 22px;
    margin-top: 2rem;
    font-weight: 600;
    color: white;
}
.loginDes{
    text-align: center;
    font-size: 14px;
    margin-top: 1rem;
    font-weight: 400;
    color: white;
}
.loginForm{
    width: 25rem; 
    margin:auto;
}
.signUp{
    text-align: center;
    margin-top: 1rem;
    font-size: 14px;
    color: white;
}
.signUp a{
    text-decoration: none;
}
.loginInput{
    border: 2px solid;
    margin-top: 10px;
}
.googleLogo{
    height: 30px;
    width: 30px;
}